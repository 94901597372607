import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { detect } from 'detect-browser';

import WideLogo from '../svgs/WideLogo';
import Loading from '../images/loading.png';
import SEO from '../components/seo';
import getEnvVars from '../environment';
import { Section } from '../styles/verify-email';

const { appSchema } = getEnvVars();

const VERIFY_EMAIL = gql`
  mutation VERIFY_EMAIL($token: String!) {
    verifyEmail(token: $token)
  }
`;

const RedirectButton = () => {
  const browser = detect();

  if (browser.os === 'iOS' || browser.os === 'Android OS' || browser.os === 'android') {
    const handleClick = () => {
      setTimeout(
        () => {
          if (browser.os === 'iOS') {
            window.location = 'https://apps.apple.com/de/app/dropfriends/id1491312504?mt=8';
          } else if (browser.os === 'Android OS' || browser.os === 'android') {
            window.location =
              'https://play.google.com/store/apps/details?id=com.dropfriends.dropfriends';
          }
        },
        browser.os === 'Android OS' || browser.os === 'android' ? 1500 : 3000
      );
    };

    return (
      <a
        onClick={handleClick}
        href={appSchema}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-8 mt-4 rounded-full focus:outline-none focus:shadow-outline">
        Zur DropFriends-App
      </a>
    );
  }

  return null;
};

const VerifyEmail = () => {
  const [status, setStatus] = useState('VERIFYING');
  const [verifyEmail, { data }] = useMutation(VERIFY_EMAIL);

  const handleVerification = async (token) => {
    await verifyEmail({ variables: { token } });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      handleVerification(token);
    } else {
      setStatus('NO_TOKEN_PROVIDED');
    }
  }, []);

  useEffect(() => {
    if (data && data.verifyEmail) {
      setStatus(data.verifyEmail);
    }
  }, [data]);

  return (
    <>
      <SEO
        title="Email verifizieren"
        meta={[
          {
            property: 'robots',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <Section>
        <WideLogo className="mb-8" />
        {status === 'VERIFYING' ? (
          <>
            <p>Deine E-Mailadresse wird verifiziert.</p>
            <div className="mt-8">
              <img src={Loading} className="h-10 spin-animation" alt="Loading Spinner" />
            </div>
          </>
        ) : null}
        {status === 'ALREADY_VERIFIED' ? (
          <>
            <p>Du bist schon verifiziert.</p>
            <RedirectButton />
          </>
        ) : null}
        {status === 'NO_TOKEN_PROVIDED' || status === 'USER_NOT_FOUND' ? (
          <p>Dein Link ist abgelaufen oder du hast einen fehlerhaften Link erhalten.</p>
        ) : null}
        {status === 'SUCCESS' ? (
          <>
            <p>E-Mail erfolgreich verifiziert. Dein Konto ist nun freigeschaltet</p>
            <RedirectButton />
          </>
        ) : null}
      </Section>
    </>
  );
};

export default VerifyEmail;
